declare global {
    interface Window { 
      gtagInit2: any; 
    }
    interface DefaultTheme { 
      breakpoints: any; 
    }
}

// Ideas /
// https://www.airbnb.com
// Image size: 1280x720 640x360 320x180
// Transition: https://reacttricks.com/animating-next-page-transitions-with-framer-motion
// https://elopify-cms.uc.r.appspot.com/places
// https://www.seoptimer.com/blog/logo-size-for-website/#Logo-dimensions-on-LinkedIn

import React from 'react'; 
const axios = require('axios').default;
// import { useRouter } from 'next/router';  

// import { getHomePagePlaces } from 'src/place'; 

import { makeStyles, useTheme } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

// import { LogoJsonLd } from 'next-seo';

// import HeadBlock from 'components/HeadBlock';
// import Link from 'components/Link'; 
import Meta from 'components/Meta';
import CardList from 'components/CardList';
import Hero from 'components/Hero'; 
import ButtonLink from 'components/ButtonLink';  

import Header from 'widgets/Header'; 
import Footer from 'widgets/Footer'; 
// import ProTip from 'widgets/ProTip'; 
// import GetStarted from 'widgets/GetStarted'; 
// import FAQ from 'widgets/FAQ';
import PlaceSearchBar from 'widgets/PlaceSearchBar'; 
// import PlacesAutocomplete from 'widgets/PlacesAutocomplete'; 
import Difference from 'widgets/Difference'; 
import VenueActions from 'widgets/VenueActions'; 

import VideoJS from 'components/VideoJS'
import SeoSnippet from 'data_files/seo';  
  
// import Button from '@material-ui/core/Button';   
const useStyles = makeStyles((_theme) => ({
    root: {
      flexGrow: 1, 
    },
    content: {
      marginTop: '24px'
    }, 
    title: {
      flexGrow: 1,
    }
  }));  

interface IProps {
  homePageDestinations: any;
  intDestinations: any;
  // any other props that come into the component
}  

// const dateObj = new Date(); 
// const year = dateObj.getUTCFullYear();
// const month = dateObj.toLocaleString('default', { month: 'short' });
// const datePart = month + ' ' + year;

export default function Index({ homePageDestinations, intDestinations }: IProps) { 
  // console.log('intDestinations ', intDestinations)
  const classes = useStyles(); 
  // console.log('process.env ', process.env)
  // console.log('homePageDestinations ', homePageDestinations) 
  const theme:any = useTheme(); 
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm')); 

  // console.log('theme ', theme)

  const playerRef = React.useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: false,
    fluid: false,
    height: 315,
    sources: [{
      src: 'https://storage.googleapis.com/globalcdn/assets/videos/main/elope-anywhere-540.mp4',
      type: 'video/mp4'
    }],
    poster: 'https://storage.googleapis.com/globalcdn/assets/videos/main/poster.png'
  }

  const handlePlayerReady = (player : any) => {
    playerRef.current = player; 

    setTimeout(() => { 
      // playerRef.current.muted(false);
    }, 2500);
 

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const context = process.env["NEXT_PUBLIC_HOSTNAME"] || 'default';

  // The ultimate collection of the best places to elope based on 30-point factors. 5 Star Rated. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware.
 let heroBgItem: { [key: string]: string; } = {};
 let heroBg: { [key: string]: typeof heroBgItem; } = {};
 
 heroBg = {
    'wedwedgo.com': {
      sm: '/img/header-bg/wedwedgo.com.jpeg',
      md: '/img/header-bg/wedwedgo.com.jpeg'
    },
    // 'allweddings.org': {
    //   sm: '/img/bg/transparent.png',
    //   md: '/img/bg/transparent.png'
    // },
    '2weddingteam2.org': {
      sm: '/img/bg/transparent.png',
      md: '/img/bg/transparent.png'
    },
    // 'elopifi.com': {
    //   sm: '/img/header-bg/1/home.jpeg',
    //   md: '/img/header-bg/1/home.jpeg'
    // },
    // 'elopefun.com': {
    //   sm: '/img/header-bg/1/home.jpeg',
    //   md: '/img/header-bg/1/home.jpeg'
    // },
    'default': {
      sm: '/img/header-bg/home.svg',
      md: '/img/header-bg/home-mobile.svg'
    }
  }; 
 
  let heroBgSm = typeof heroBg[context] !== 'undefined' ? heroBg[context].sm : heroBg['default'].sm;
  let heroBgMd = typeof heroBg[context] !== 'undefined' ? heroBg[context].md : heroBg['default'].md;

  // console.log('heroBgSm ', heroBgSm)
  // console.log('heroBgMd ', heroBgMd)

  // H2 color #c978e0
  // Logo color
  // background: -webkit-linear-gradient(45deg, #bb73E0, #FF8DDF);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent; 
  // city card height height: 300px;
  // hero img height: 375px;
  // search input margin-top: -50px;

  // const companyName = typeof process.env["NEXT_PUBLIC_COMPANY_NAME"] !== 'undefined' 
  //   ? process.env["NEXT_PUBLIC_COMPANY_NAME"]
  //   : 'Best Weddings';

  // let title: { [key: string]: string; } = {};  
  // title = {
  //   'default': `${companyName} — Elope Anywhere!`,
  //   'wedwedgo.com': `THE 850+ BEST Destination Wedding Venues and Destination Wedding Packages Starting at $1290`,
  //   'elopifi.com': `THE 1750+ BEST Places to Elope — The Best Elopements!`,
  //   'elopefun.com': `THE 1750+ BEST Places to Elope — The Best Elopements!`,
  // };

  // let description: { [key: string]: string; } = {};
  // description = {
  //   'default': `The Ultimate Collection of Places to Elope based on 30-point factors. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware. Starting at $1290!`,
  //   'wedwedgo.com': `The Exquisite Destination wedding planner. Make Your Dream Wedding Abroad a Reality. Starting at $1290!`,
  //   'elopifi.com': `The Ultimate Collection of Places to Elope based on 30-point factors. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware. Starting at $299!`,
  //   'elopefun.com': `The Ultimate Collection of Places to Elope based on 30-point factors. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware. Starting at $299!`,
  //   'smartweddings.org': `The Ultimate Collection of Places to Elope based on 30-point factors. LGBTQ Friendly. Environmentally Conscious. Leave No Trace Aware. Starting at $299!`,
  // }; 

  // let heroTitle: { [key: string]: string; } = {};
  // heroTitle = {
  //   'default': `Elope Anywhere`,
  //   'wedwedgo.com': `Destination Wedding Anywhere`,
  //   'allweddings.org': `Elope Anywhere`,
  //   '2weddingteam2.org': `Elope Anywhere`,
  //   'elopifi.org': `Elope Worldwide`,
  //   'elopefun.org': `Elope Worldwide`,
  //   'smartweddings.org': `Elope Anywhere`,
  // }; 

  // let popularLocalDestinationsTitle: { [key: string]: string; } = {};
  // popularLocalDestinationsTitle = {
  //   'default': `Popular Elopement Destinations in the USA`,
  //   'wedwedgo.com': `Popular Destination Wedding Locations in the USA`,
  // }; 

  // let popularLocalDestinationsSubtitle: { [key: string]: string; } = {};
  // popularLocalDestinationsSubtitle = {
  //   'default': `A selection of expertly picked elopement destinations in the United States`,
  //   'wedwedgo.com': `A selection of expertly picked locations for a destination wedding in the United States`,
  // }; 

  // let popularOutsideDestinationsTitle: { [key: string]: string; } = {};
  // popularOutsideDestinationsTitle = {
  //   'default': `Popular International Elopement Destinations`,
  //   'wedwedgo.com': `International Destination Wedding Locations`,
  // };

  // let popularOutsideDestinationsSubtitle: { [key: string]: string; } = {};
  // popularOutsideDestinationsSubtitle = {
  //   'default': `A selection of expertly picked elopement destinations around the world`, 
  //   'wedwedgo.com': `A selection of expertly picked locations for a destination wedding around the world`, 
  // }; 

  return (
    <React.Fragment>
      <div style={{
        backgroundImage: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && ['2weddingteam2.org'].includes(process.env["NEXT_PUBLIC_HOSTNAME"])
         ? matchesSm ? "url('/img/bg/allweddings.jpg')" :  "url('/img/bg/allweddings-mobile.jpg')" 
         : "none",
        backgroundSize:  "cover",
        backgroundRepeat: "no-repeat no-repeat"
      }}>
      <Meta 
        title={SeoSnippet(context, 'home_meta_title')} 
        description={SeoSnippet(context, 'home_meta_description')}
        slug="/"
      />
      {/* <LogoJsonLd
        logo={`https://${process.env["NEXT_PUBLIC_HOSTNAME"]}/img/logo/icon-transparent.png`}
        url={`https://${process.env["NEXT_PUBLIC_HOSTNAME"]}`}
      /> */}
      <Header className={"headerWrapHomePage"}/> 
      <Hero params={{
        src: matchesSm 
          ? heroBgSm : heroBgMd,
        title: SeoSnippet(context, 'home_hero_title'),
        subtitle: "",
        variant: "h2",
        component: "h1"
      }}/>  
      <Container className={classes.content} maxWidth="md">  
        <PlaceSearchBar/>   
        {typeof homePageDestinations !== 'undefined' && homePageDestinations.length > 0 ?
          <Box sx={{ my: 5 }}>
            <Typography style={{margin: 0}} component="h2" variant="h6" gutterBottom>
              {SeoSnippet(context, 'home_popular_local_destinations_title')} 
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {SeoSnippet(context, 'home_popular_local_destinations_subtitle')}
            </Typography>  
            <CardList items={homePageDestinations}></CardList>
          </Box>
          : null
        }
        {typeof intDestinations !== 'undefined' && intDestinations.length > 0 
            ? <Box sx={{ my: 5 }}>
                <Typography style={{margin: 0}} component="h2" variant="h6" gutterBottom>
                  {SeoSnippet(context, 'home_popular_other_destinations_title')}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                   {SeoSnippet(context, 'home_popular_other_destinations_subtitle')}
                </Typography>  
                <CardList items={intDestinations.filter((place:any) => place.name != 'United States')}></CardList>  
                </Box>
            : null
          } 
        <Box sx={{ my: 5, textAlign: 'center', display: 'none' }}>
          <ButtonLink href="/destinations" variant="contained" color="primary">Explore more</ButtonLink> 
        </Box> 

        {typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] !== '' ? <>
        <br/>
        <Box sx={{ my: 4 }}> 
          <VenueActions />
        </Box>  <br/>        
        <Box style={{
          visibility: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && ['2weddingteam2.org'].includes(process.env["NEXT_PUBLIC_HOSTNAME"])
            ? "hidden"
            : "visible", 
          display: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && ['2weddingteam2.org'].includes(process.env["NEXT_PUBLIC_HOSTNAME"]) && matchesSm === false 
            ? "none"
            : "block" 
        }} sx={{ my: 4 }}> 
          
         <Difference context={context} />

        </Box>
        </> : null }
        {typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && !['2weddingteam2.org', 'elopifi.com', 'elopefun.com'].includes(process.env["NEXT_PUBLIC_HOSTNAME"]) ? 
          <div> 
          <br/>
          <div style={{maxWidth: 560, width: '100%', textAlign: 'center', margin: 'auto'}}> 
            <VideoJS  id="video" options={videoJsOptions} onReady={handlePlayerReady} />   
          </div> 
        </div> : null}
      </Container>
      <Footer/>
      </div>  
    </React.Fragment>
  );
} 

// https://nextjs.org/blog/next-9-3#next-gen-static-site-generation-ssg-support
export async function getStaticProps(context : any) {   
  // Call an external API endpoint to get posts.

  console.log('context ', context);

  let homePlacesJson = {
    data: []
  };

  let intPlacesJson = {
    data: []
  };

  let allowedHostnames = [
    'placestoelope.org',
    'smartweddings.org',
    'smartywedding.com',
    'popularweddings.org',
    'wedwedgo.com',
    'elopli.com',
    'elopifi.com',
    'elopefun.com',
    'allweddings.org',
    'weddingteam.org'
  ]
   
  if (typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && allowedHostnames.includes(process.env["NEXT_PUBLIC_HOSTNAME"])) { 
    try {
      homePlacesJson = await axios.get(`${process.env["NEXT_PUBLIC_API_HOST"]}/v1/places?showOnHomePage=true`); 
      
      // if (process.env["NEXT_PUBLIC_HOSTNAME"] !== 'elopifi.com' && process.env["NEXT_PUBLIC_HOSTNAME"] !== 'elopefun.com') {
        intPlacesJson = await axios.get(`${process.env["NEXT_PUBLIC_API_HOST"]}/v1/places?showOnIntDestinationList=true`); 
      // }
      // console.log('homePlacesJson ', homePlacesJson.data)  
      // console.log('intPlacesJson ', intPlacesJson.data)  

      // By returning { props: posts }, the Blog component
      // will receive `posts` as a prop at build time
      // https://res.cloudinary.com/demo/w_320,dpr_auto/bike.jpg  
    } catch (error) {
      console.error(error);
    }
  }

  // const notFound = !homePlacesJson.data[0] ? true : false;

  let result = {
    props: {
      posts: [],
      homePageDestinations: homePlacesJson.data,
      intDestinations: intPlacesJson.data,
    },
    revalidate: 300,
    notFound: false
  }; 

  return result; 
}   