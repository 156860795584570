let today = new Date(); 
let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
let expires = today.getDate() <= 15 ? today.getMonth() + 1 + '/15' : today.getMonth() + 1 + '/' + lastDayOfMonth.getDate();

let snippets = {
  "home_meta_title": `THE 125+ BEST Elopement Planners in ${today.getFullYear()}-${today.getFullYear() + 1}`,
  "home_meta_description": "Explore the top 125+ elopement planners for 2023-2024, offering personalized experiences starting at $499. Let expert guidance transform your intimate celebration into an unforgettable moment.", 
  "home_hero_title": "Explore Anywhere", 
  "home_popular_local_destinations_title": "Best Elopement Planners in the USA",
  "home_popular_local_destinations_subtitle": "We will connect you with elopement planners in the United States",   
  "home_popular_other_destinations_title": "Best International Elopement Planners",
  "home_popular_other_destinations_subtitle": "We will connect you with elopement planners in the around the world",
  "home_feature_block_title": "Our secret sauce",
  "home_feature_block_subtitle": "Here's what makes us the smarter elopement choice", 
  "home_feature1_title": "Expertly picked",
  "home_feature1_subtitle": "Elopement planners with an exceptional track record",  
  "home_feature2_title": "Secure Payments",
  "home_feature2_subtitle": "When disaster strikes, your event funds are safe",  
  "home_feature3_title": "Custom itineraries",
  "home_feature3_subtitle": "Your elopement planner will make sure everything is just right",
}; 

export default snippets