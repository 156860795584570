let today = new Date(); 
let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
let expires = today.getDate() <= 15 ? today.getMonth() + 1 + '/15' : today.getMonth() + 1 + '/' + lastDayOfMonth.getDate();

let snippets = {
  "home_meta_title": `THE 55+ Popular Wedding Destinations in ${today.getFullYear()}-${today.getFullYear() + 1}`,
  "home_meta_description": "Explore the most popular wedding destinations globally, with 265+ handpicked choices. Start your special day from $1290. Tailor your perfect celebration with our unique options.", 
  "home_hero_title": "Tie the Knot Anywhere", 
  "home_popular_local_destinations_title": "Popular Wedding Destinations in the USA",
  "home_popular_local_destinations_subtitle": "Check out our cool wedding spots across the USA",   
  "home_popular_other_destinations_title": "Popular International Wedding Destinations",
  "home_popular_other_destinations_subtitle": "Check out our cool wedding spots around the world",
  "home_feature_block_title": "Our special ingredients",
  "home_feature_block_subtitle": "Here's why we're the savvy pick for desitination weddings", 
  "home_feature1_title": "Carefully chosen",
  "home_feature1_subtitle": "Epic elopement spots with stunning views",  
  "home_feature2_title": "Safe Payments",
  "home_feature2_subtitle": "When the unexpected happens, your event money is secure",  
  "home_feature3_title": "Tailored itineraries",
  "home_feature3_subtitle": "Your elopement planner ensures everything is perfect",
}; 

export default snippets