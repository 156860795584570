import Default from './default';   
import SmartWeddings from './smartweddings'; 
import WedWedGo from './wedwedgo'; 
import ElopeFun from './elopefun'; 
import PlacesToElope from './placestoelope'; 
import Elopli from './elopli'; 
import PopularWeddings from './popularweddings'; 

// 

let snippets = {
	'default': Default, 
  'wedwedgo.com': WedWedGo,  
  'smartweddings.org': SmartWeddings,  
  'elopefun.com': ElopeFun,  
  'elopli.com': Elopli,  
  'placestoelope.org': PlacesToElope,  
  'popularweddings.org': PopularWeddings,  
  'smartywedding.com': SmartWeddings, 
}  

let SeoSnippet = (context, name) => {
	if (typeof snippets[context] === 'undefined') {
		context = 'default';
	}

	console.log(context)

	if (typeof snippets[context][name] !== 'undefined') {
		return snippets[context][name];
	} else {
		return '';
	}
}

export default SeoSnippet