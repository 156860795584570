import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ButtonLink from 'components/ButtonLink'; 
import Box from '@material-ui/core/Box';
import SeoSnippet from 'data_files/seo';  

import LazyLoad from 'react-lazyload';

const useStyles = makeStyles((theme) => ({
	root: {
    marginBottom: '55px'
  },
  itemListSm: {
  	display: 'flex',
    flexGrow: 1, 
    flexDirection: 'row',
    marginLeft: '-8px'
  },
  itemListXl: {
  	display: 'flex',
    flexGrow: 1, 
    flexDirection: 'column',
    marginLeft: '-8px'
  },
  itemWrap: {
  	height: '100px',
  	flex: 1,
  	alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginTop: '24px',
    padding: '0 8px'
  },
  itemImg: { 
  	height: '28px',
    width: '28px'
  },
  itemTitle: { 
  	fontWeight: 600,
    marginTop: '14px'	
  },
  itemText: { 
  	marginTop: '4px'	
  }
})); 

export default function Difference({context}) {
  const theme = useTheme(); 
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));  

  const classes = useStyles();  

  return (
    <Box
      className={classes.root}
      sx={{
        textAlign: "left"
      }}>
  		<Typography className={classes.title} style={{margin: 0}} component="h2" variant="h6" gutterBottom>
        {SeoSnippet(context, "home_feature_block_title")}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        {SeoSnippet(context, "home_feature_block_subtitle")}
      </Typography> 
      <LazyLoad>   
      <div className={matchesSm ? classes.itemListSm : classes.itemListXl}>
	  		<div className={classes.itemWrap}>
	  			<img className={classes.itemImg} width="28" height="28" src="/img/icons/1.svg" alt="" />
	  			<div className={classes.itemTitle}>{SeoSnippet(context, "home_feature1_title")}</div>
	  			<div className={classes.itemText}>{SeoSnippet(context, "home_feature1_subtitle")}</div>
	  		</div>
	  		<div className={classes.itemWrap}>
	  			<img className={classes.itemImg} width="28" height="28" src="/img/icons/2.svg" alt="" />
	  			<div className={classes.itemTitle}>{SeoSnippet(context, "home_feature2_title")}</div>
          <div className={classes.itemText}>{SeoSnippet(context, "home_feature2_subtitle")}</div>
	  		</div>
	  		<div className={classes.itemWrap}>
	  			<img className={classes.itemImg} width="28" height="28" src="/img/icons/3.svg" alt="" />
	  			<div className={classes.itemTitle}>{SeoSnippet(context, "home_feature3_title")}</div>
          <div className={classes.itemText}>{SeoSnippet(context, "home_feature3_subtitle")}</div>
	  		</div>
  		</div> 
      </LazyLoad>   
    </Box>
  );
}2
