let today = new Date(); 
let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
let expires = today.getDate() <= 15 ? today.getMonth() + 1 + '/15' : today.getMonth() + 1 + '/' + lastDayOfMonth.getDate();

let snippets = {
  "home_meta_title": `THE 2750+ BEST Places to Elope in ${today.getFullYear()}-${today.getFullYear() + 1}`,
  "home_meta_description": "Discover the Ultimate Guide to Elopement Locations in 2023-2024 with our curated list of 2750+ breathtaking places. Start your journey to an intimate celebration, with packages beginning at $985", 
  "home_hero_title": "Elope Anywhere", 
  "home_popular_local_destinations_title": "Best Places to Elope in the USA",
  "home_popular_local_destinations_subtitle": "A selection of expertly picked places to elope in the United States",   
  "home_popular_other_destinations_title": "Best International Places to Elope",
  "home_popular_other_destinations_subtitle": "A selection of expertly picked places to elope around the world",
  "home_feature_block_title": "Our secret sauce",
  "home_feature_block_subtitle": "Here's what makes us the smarter elopement choice", 
  "home_feature1_title": "Expertly picked",
  "home_feature1_subtitle": "Places to elope with exceptionally beautiful views",  
  "home_feature2_title": "Secure Payments",
  "home_feature2_subtitle": "When disaster strikes, your event funds are safe",  
  "home_feature3_title": "Custom itineraries",
  "home_feature3_subtitle": "Your elopement planner will make sure everything is just right",
}; 

export default snippets