let today = new Date(); 
let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
let expires = today.getDate() <= 15 ? today.getMonth() + 1 + '/15' : today.getMonth() + 1 + '/' + lastDayOfMonth.getDate();

let snippets = {
  "home_meta_title": `THE 75+ BEST Destination Wedding Packages in ${today.getFullYear()}-${today.getFullYear() + 1}`,
  "home_meta_description": "Explore the finest destination wedding packages globally, featuring 375+ curated options. Begin your intimate celebration starting at $1290. Craft your perfect moment with our exclusive offerings.", 
  "home_hero_title": "Elope Anywhere", 
  "home_popular_local_destinations_title": "Popular Elopement Destinations in the USA",
  "home_popular_local_destinations_subtitle": "A selection of expertly picked elopement destinations in the United States",   
  "home_popular_other_destinations_title": "Popular International Elopement Destinations",
  "home_popular_other_destinations_subtitle": "A selection of expertly picked elopement destinations around the world",
  "home_feature_block_title": "Our secret sauce",
  "home_feature_block_subtitle": "Here's what makes us the smarter elopement choice", 
  "home_feature1_title": "Expertly picked",
  "home_feature1_subtitle": "Elopement places with exceptionally beautiful views",  
  "home_feature2_title": "Secure Payments",
  "home_feature2_subtitle": "When disaster strikes, your event funds are safe",  
  "home_feature3_title": "Custom itineraries",
  "home_feature3_subtitle": "Your elopement planner will make sure everything is just right",
}; 

export default snippets